import ModelListCommon from "./ModelListCommon";

import ModelCollectionAccount  from "./model/ModelCollectionAccount"
import AccountConnector  from "./connectors/AccountConnector"
import Account, { IAccount, AccountUuid } from "./model/Types/Account";
import ArraySortedAccount from "./ArraySortedAccount";
import { IAccountLog } from "./model/Types/IAccountLog";
import ModelArray from "./model/ModelArray";


export default class ModelListAccount extends ModelListCommon {

    static readonly EVENT_HISTORY_MAIN = "history_main";
    static readonly EVENT_HISTORY_RATING = "history_rating";
    static readonly EVENT_DATA_CHANGED_ACCOUNT_MAIN = "changeAccountMain";

    models:ModelCollectionAccount
    connector:AccountConnector
    modelsSorted:ArraySortedAccount

    modelsLogMain:ModelArray<IAccountLog>
    modelsLogRating:ModelArray<IAccountLog>
            
    constructor(userConnector:any ) {           
        super(userConnector);
        this.models = new ModelCollectionAccount();
        this.connector = new AccountConnector(this.socket); 
        this.connector.verbose = process.env.DEBUG_VERBOSE ? true : false;
        this.modelsSorted = new ArraySortedAccount(this.models);                 

        this.modelsLogMain = new ModelArray<IAccountLog>();
        this.modelsLogRating = new ModelArray<IAccountLog>();

        this.modelsLogMain.keyName="id"
        this.modelsLogRating.keyName="id"
                               
        this.connector.cb_account_changed_any = (data) => {
            if(!this.filled)           
                return;

            this.models.itemUpdate(data.account)
            this.onListChanged();                                                  
            this.clearHistoryCacheForAccount(data.account);
        }   

        this.connector.cb_account_select = (data) => {
            if(!this.filled)           
                return;
            this.doDataFetch(); // todo mark account as selected , clear mark on other            
        }   
    }

    getEventData():any { return this.modelsSorted; }
    getConnector():AccountConnector { return this.connector; }
            
    async doDataClear() {  
        super.doDataClear();      
        this.models.clear();        
        this.onListChanged();       

        this.doClearHistoryCacheMain();
        this.doClearHistoryCacheRating(); 
    }    

    async doDataFetch() {             
        super.doDataFetch(); 

        this.models.clear();        
        try {
            let result = await this.connector.doGetListSearch( {}, {page:0,pageSize:100});
            this.models.itemUpdateArray(result.models)        
            if(result.models.length)
                await this.onListChanged(result.meta); 
        }
        catch(e) {
            console.log( "ModelListAccount::doDataFetch() failed:", e )
        }
        
    }

    async onListChanged(meta?:any) {                                
        this.modelsSorted.update(this.models );
        this.emit(ModelListCommon.EVENT_DATA_CHANGED, this.getEventData());       
    }

    async clearHistoryCacheForAccount( account:IAccount) {        
        if(this.modelsSorted.accountsMoney.map(account => account.uuid).includes(account.uuid))
            return this.doClearHistoryCacheMain()

        if(this.modelsSorted.accountRating && account.uuid == this.modelsSorted.accountRating.uuid)
            return this.doClearHistoryCacheRating()
    }
    async doClearHistoryCacheMain(emit=true) {    
        let models = this.modelsLogMain;     
        models.clear();
        if(emit)       
            this.emit(ModelListAccount.EVENT_HISTORY_MAIN, models)
    }

    async doClearHistoryCacheRating(emit=true) {        
        let models = this.modelsLogRating; 
        models.clear();
        if(emit)       
            this.emit(ModelListAccount.EVENT_HISTORY_RATING, models)
    }
        
    async doLoadMoreHistoryMain(emit=true) {     
        let models = this.modelsLogMain; 
        let account = this.modelsSorted.accountsMoney;        
        await this.doLoadMoreHistory(models, account); 
        if(emit)       
            this.emit(ModelListAccount.EVENT_HISTORY_MAIN, models)
    }

    async doLoadMoreHistoryRating(emit=true) {     
        let models = this.modelsLogRating; 
        let account = this.modelsSorted.accountRating;        
        await this.doLoadMoreHistory(models, account);        
        if(emit)       
            this.emit(ModelListAccount.EVENT_HISTORY_RATING, models)
    }



    async doLoadMoreHistory(models:ModelArray<IAccountLog>, account?:Account | Account[]) {      
        if(!account)
            return;
        let result = await this.doLoadMoreRecords(models.models,account)                
        models.setCountTotal( result.pagination.totalCount );
        models.doExpand(result.models);
        models.doFilterUnique();        
    }

    async doLoadMoreRecords(models:any[],account:Account | Account[]) {
        let pageSize = 20;                
        let pagesLoaded = Math.floor(models.length / pageSize)
        let page = pagesLoaded;
        return await this.connector.doHistorySearch(
            Array.isArray(account)?
                account.map(account => account.uuid):
                account.uuid, 
            {}, 
            {page, pageSize}
        );        
    }


    async doLoadHistoryMain(emit=true) {     
        let models = this.modelsLogMain; 
        let account = this.modelsSorted.accountsMoney;        
        await this.doLoadHistory(models, account); 
        if(emit)       
            this.emit(ModelListAccount.EVENT_HISTORY_MAIN, models)
    }

    async doLoadHistoryRating(emit=true) {     
        let models = this.modelsLogRating; 
        let account = this.modelsSorted.accountRating;        
        await this.doLoadHistory(models, account);        
        if(emit)       
            this.emit(ModelListAccount.EVENT_HISTORY_RATING, models)
    }

    async doLoadHistory(models:ModelArray<IAccountLog>, account?:Account | Account[]) {      
        if(!account)
            return;
        let result = await this.doLoadRecords(models.models,account) 
        models.clear()               
        models.setCountTotal( result.pagination.totalCount );
        models.doExpand(result.models);
        models.doFilterUnique();        
    }

    async doLoadRecords(models:any[],account:Account | Account[]) {
        let pageSize = 20;                
        let pagesLoaded = Math.floor(models.length / pageSize)
        let page = pagesLoaded > 0 ? pagesLoaded - 1 : pagesLoaded;
        return await this.connector.doHistorySearch(
            Array.isArray(account)?
                account.map(account => account.uuid):
                account.uuid,
            {}, 
            {page, pageSize}
        );        
    }

}